import React, { useContext, useState } from 'react';
import './styles.scss';
import { SelectDeviceProps } from './interfaces';
import Dropdown from '../../components/Dropdown';
import { ScreenEnum } from '../../enums';
import Progress from '../../components/Progress';
import { useTranslation } from 'react-i18next';
import AppStateContext from '../../AppStateProvider/context';
import RadioButtons from '../../components/RadioButtons';
import i18next from 'i18next';
import { CheckmarkPasswordToggle } from '../../assets/Icons';
import { Password } from '../Password';
import PasswordOverlay from '../../components/PasswordOverlay';

export default function SelectDevice(props: SelectDeviceProps) {
	const { t } = useTranslation();
	const { state, dispatch } = useContext(AppStateContext);

	const [individualOrKvps, setIndividualOrKvps] = useState(
		state.userPassword ? 'individual' : 'kvps'
	);
	const [passwordToggle, setPasswordToggle] = useState(false);
	const [showDeletePasswordWarning, setShowDeletePasswordWarning] =
		useState(false);

	const individualPasswordClickHandler = () => {
		setPasswordToggle(true);
	};

	const kvpsPasswordClickHandler = () => {
		if (state.userPassword) {
			setShowDeletePasswordWarning(true);
		}
	};

	if (state.devices === null) {
		return <h1>{t('SelectDeviceNoDevices')}</h1>;
	}

	return (
		<>
			<div className='top'>
				<div
					className={`select-device ${
						passwordToggle ? 'opacity' : ''
					}`}
				>
					{props.backBtn(ScreenEnum.selectMarket)}
					<h1>{t('SelectDeviceHeadline')}</h1>
					{state.device && /^\d{3}$/.test(state.device) ? (
						<p className='p1 error-message'>
							{t('SelectDeviceError' + state.device)}
						</p>
					) : (
						<p className='p1'>{t('SelectDeviceText')}</p>
					)}
					<Progress
						screen={ScreenEnum.selectDevice}
						setScreen={props.setScreen}
					/>
					{state.devices.length > 0 ? (
						<label htmlFor='device'>
							<h4>{t('SelectDeviceLabelDevice')}</h4>
							<Dropdown
								list={state.devices}
								set={(selectedDevice: string) => {
									const deviceId =
										selectedDevice.match(/\(([^)]+)\)$/);
									if (deviceId) {
										dispatch({
											type: 'SET_DEVICE',
											value: deviceId[1],
										});
									}
								}}
								defaultValue={state.devices.find(d =>
									d.includes(`(${state.device || ''})`)
								)}
								placeholder={t('SelectDevicePlaceholderDevice')}
							/>
						</label>
					) : (
						<h4>{t('SelectDevicesFoundNone')}</h4>
					)}
					{!state.apk && (
						<RadioButtons
							options={[
								{
									selected: !state.goToLabel,
									text: t('SetDeviceShowPricesheet'),
									value: false,
								},
								{
									selected: state.goToLabel,
									text: t('SetDeviceShowLabel'),
									value: true,
								},
							]}
							onClick={(value: boolean) =>
								dispatch({
									type: 'SET_GO_TO_LABEL',
									value: value,
								})
							}
						/>
					)}
					<div
						className={`select-password ${state.apk ? 'apk' : ''}`}
					>
						<p className='bold'>
							{i18next.t('SelectPasswordTitle')}
						</p>
						<p>
							{individualOrKvps === 'kvps'
								? i18next.t('SelectPasswordKvps')
								: i18next.t('SelectPasswordIndividual')}
						</p>
					</div>
					{
						<div
							className={`password-toggle-wrapper ${
								state.apk ? 'apk' : ''
							}`}
						>
							<div className='password-toggle'>
								<button
									className={`${
										individualOrKvps === 'individual'
											? 'active'
											: ''
									}`}
									type='button'
									onClick={individualPasswordClickHandler}
								>
									<div>
										<p>
											{individualOrKvps === 'individual'
												? i18next.t(
														'IndividualPasswordAsPassword'
												  )
												: i18next.t(
														'IndividualPassword'
												  )}
										</p>
										<p>
											{individualOrKvps ===
												'individual' &&
												CheckmarkPasswordToggle}
										</p>
									</div>
								</button>
								<button
									className={`${
										individualOrKvps === 'kvps'
											? 'active'
											: ''
									}`}
									type='button'
									onClick={kvpsPasswordClickHandler}
								>
									<div>
										<p>
											{individualOrKvps === 'kvps'
												? i18next.t('KVPSAsPassword')
												: i18next.t('KvpsPassword')}
										</p>
										<p>
											{individualOrKvps === 'kvps' &&
												CheckmarkPasswordToggle}
										</p>
									</div>
								</button>
							</div>
						</div>
					}
				</div>
			</div>
			{!passwordToggle && (
				<div className='buttons'>
					<button
						className='white'
						disabled={state.device === null}
						onClick={() =>
							props.setScreen(ScreenEnum.validationDevice)
						}
					>
						{t('ButtonConfirm')}
					</button>
					{props.cancelBtn}
				</div>
			)}

			{passwordToggle && (
				<Password
					cancelBtn={() => {
						setPasswordToggle(false);
					}}
					saveBtn={() => {
						setPasswordToggle(false);
						setIndividualOrKvps('individual');
					}}
					setkvps={() => setIndividualOrKvps('kvps')}
				/>
			)}
			{showDeletePasswordWarning && (
				<PasswordOverlay
					button={() => {
						setShowDeletePasswordWarning(false);
						dispatch({
							type: 'SET_USER_PASSWORD',
							value: null,
						});
						dispatch({
							type: 'SET_SCREEN',
							value: ScreenEnum.selectDevice,
						});
						setIndividualOrKvps('kvps');
					}}
					cancel={() => {
						setShowDeletePasswordWarning(false);
					}}
					showButton={true}
					title={i18next.t('DeletePassword')}
					text={'DeletePasswordsTexts'}
				/>
			)}
		</>
	);
}
