import React, { useState } from 'react';
import './styles.scss';
import Progress from '../../components/Progress';
import { SetSecondProps } from './interfaces';
import { useTranslation } from 'react-i18next';
import { ScreenEnum } from '../../enums';
import RadioButtons from '../../components/RadioButtons';
import Option from '../../components/Option';
import addParam from '../../utils/addParam';
import { deviceTypeList } from '../../utils/deviceTypeList';

export default function SetSecond(props: SetSecondProps) {
	const { t } = useTranslation();
	const [orientation, setOrientation] = useState<string>('');
	const [showSecond, setShowSecond] = useState<boolean>(props.showSecond);

	function handleClick(value: boolean | string) {
		if (value === true) {
			addParam('orientation', orientation, true);
			setShowSecond(value);
		} else if (value === false) {
			addParam('orientation', null, true);
			setShowSecond(value);
		} else {
			addParam('orientation', value, true);
			setOrientation(value);
		}
	}

	return (
		<>
			<div className='top'>
				<div className='set-second'>
					{props.backBtn(ScreenEnum.welcome)}
					<h1>{t('SetSecondHeadline')}</h1>
					<p className='p1'>{t('SetSecondText')}</p>
					<Progress
						screen={ScreenEnum.setSecond}
						setScreen={props.setScreen}
					/>
					<Option
						selected={showSecond}
						text={t('SetSecondShow')}
						onClick={() => handleClick(!showSecond)}
						className='show-second'
						checkMark={true}
					/>
					<RadioButtons
						headline={t('SetSecondRotation')}
						disabled={!showSecond}
						options={[
							{
								selected: orientation === 'default',
								text: '0°',
								value: 'default',
							},
							{
								selected: orientation === 'right',
								text: '90°',
								value: 'right',
							},
							{
								selected: orientation === 'down',
								text: '180°',
								value: 'down',
							},
							{
								selected: orientation === 'left',
								text: '270°',
								value: 'left',
							},
						]}
						onClick={(value: string) => handleClick(value)}
					/>
				</div>
			</div>
			<div className='buttons'>
				<button
					className='white'
					onClick={() =>
						props.setScreen(
							deviceTypeList.length > 1 && orientation !== 'left'
								? ScreenEnum.hardwareCheck
								: ScreenEnum.softwareCheck
						)
					}
					disabled={!showSecond || orientation === ''}
				>
					{t('SetSecondConfirm')}
				</button>
				<button
					className='black'
					onClick={() =>
						props.setScreen(
							deviceTypeList.length > 1
								? ScreenEnum.hardwareCheck
								: ScreenEnum.softwareCheck
						)
					}
				>
					{t('ButtonSkip')}
				</button>
			</div>
		</>
	);
}
