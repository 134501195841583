import React from 'react';
import Waiting from '../../components/Waiting';
import { ScreenEnum } from '../../enums';
import { useTranslation } from 'react-i18next';
import { SoftwareCheckProps } from './interfaces';

export default function SoftwareCheck(props: SoftwareCheckProps) {
	// const { state } = useContext(AppStateContext);
	const { t } = useTranslation();
	let translations = {
		headline: t('SoftwareCheckHeadline'),
		text: t('SoftwareCheckText'),
	};

	// if (props.checked === false) {
	// 	localStorage.setItem('state', JSON.stringify(state));
	// 	localStorage.setItem(
	// 		'software-check-earliest',
	// 		new Date(Date.now() + 2000).toISOString()
	// 	);
	// 	localStorage.setItem('translations', JSON.stringify(translations));
	// 	addParam('software-check', false);
	// }
	//
	// if (!state.apk || (props.checked === true && props.warning === undefined)) {
	// 	localStorage.removeItem('state');
	// 	localStorage.removeItem('translations');
	// 	addParam('software-check', null);
	// 	const earliest = localStorage.getItem('software-check-earliest');
	// 	const remainingMinTime = earliest
	// 		? new Date(earliest).getTime() - Date.now()
	// 		: 0;
	// 	setTimeout(
	// 		() => props.setScreen(ScreenEnum.selectBrand),
	// 		remainingMinTime > 0 ? remainingMinTime : 0
	// 	);
	// }
	//
	// if (props.checked === undefined) {
	// 	translations = JSON.parse(localStorage.getItem('translations') || '{}');
	// }

	// remove for real checks in apk
	setTimeout(() => props.setScreen(ScreenEnum.selectBrand), 2000);

	return (
		<>
			<div className='top'>
				<div className='software-check'>
					{props.backBtn(ScreenEnum.hardwareCheck)}
					<Waiting
						screen={ScreenEnum.softwareCheck}
						setScreen={props.setScreen}
						headline={translations?.headline}
						text={translations?.text}
					/>
					{props.warning}
				</div>
			</div>
		</>
	);
}
